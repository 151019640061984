import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0,5,6,7];

export const dictionary = {
		"/": [9],
		"/(user)/admin": [16,[5,6]],
		"/(user)/admin/bot": [17,[5,6]],
		"/(user)/admin/bot/users": [~18,[5,6]],
		"/(user)/admin/create_maket": [~19,[5,6]],
		"/(user)/admin/crm": [~20,[5,6]],
		"/(user)/admin/stickerpacks": [~21,[5,6]],
		"/(user)/auth/callback/auth-code-error": [22,[5]],
		"/(user)/auth/callback/check_email": [23,[5]],
		"/(user)/auth/callback/confirmed": [~25,[5]],
		"/(user)/auth/callback/confirm": [~24,[5]],
		"/(user)/auth/callback/otp": [~26,[5]],
		"/(user)/auth/reset_password": [~27,[5]],
		"/(user)/auth/signIn": [~28,[5]],
		"/(user)/auth/signOTP": [29,[5]],
		"/(user)/auth/signOut": [~30,[5]],
		"/(user)/auth/signUp": [~31,[5]],
		"/(user)/auth/update_password": [~32,[5]],
		"/(service)/generate": [10,[2],[,3]],
		"/(show)/privacy": [13],
		"/(show)/p/[nanoid]": [~12],
		"/(show)/series/[nanoid]": [~15],
		"/(show)/s/[nanoid]": [~14,[],[4]],
		"/(user)/user": [~33,[5]],
		"/(user)/user/links": [~34,[5]],
		"/(user)/user/links/[link_nanoid]": [~35,[5]],
		"/(user)/user/pay": [36,[5]],
		"/(user)/user/points": [~37,[5]],
		"/(user)/user/points/add": [~46,[5]],
		"/(user)/user/points/[nanoid]/analytics": [~38,[5]],
		"/(user)/user/points/[nanoid]/analytics/links": [~39,[5]],
		"/(user)/user/points/[nanoid]/analytics/question": [~40,[5]],
		"/(user)/user/points/[nanoid]/analytics/stickers": [~41,[5]],
		"/(user)/user/points/[nanoid]/edit": [42,[5,7]],
		"/(user)/user/points/[nanoid]/edit/buttons": [43,[5,7]],
		"/(user)/user/points/[nanoid]/edit/general": [44,[5,7]],
		"/(user)/user/points/[nanoid]/edit/links": [45,[5,7]],
		"/(user)/user/profile": [~47,[5]],
		"/(user)/user/profile/orders": [~48,[5]],
		"/(user)/user/profile/orders/create": [~49,[5]],
		"/(user)/user/profile/subscriptions": [~50,[5]],
		"/(user)/user/questions": [~51,[5]],
		"/(user)/user/questions/add": [~52,[5]],
		"/(user)/user/questions/edit": [~53,[5]],
		"/(user)/user/questions/stat/[question_id]": [~54,[5]],
		"/(user)/user/series/[nanoid]": [~55,[5],[,8]],
		"/(user)/user/stickers": [~56,[5]],
		"/(user)/user/stickers/[sticker_nanoid]": [~57,[5]],
		"/(user)/user/tariffs": [~58,[5]],
		"/(show)/[link_nanoid]": [~11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';